import React, { useState } from "react";
import {
  ReactiveBase,
  SearchBox,
  ReactiveList,
  ResultCard,
  MultiList,
  MultiRange,
} from "@appbaseio/reactivesearch";
import ".././App.css";
import { withRouter } from "react-router-dom";
import Responsive from "react-responsive-decorator";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Menu from "../Mobile/Menu";
import { elasticSearchUrl } from "../common/util";
import Breadcrumbs from "../layout/Breadcrumb";
import Header from "../layout/Header";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ScrollButton from "../ScrollButton";
import "../App.css"
export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

const CategoryDetalis = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get("q");

  const [sideMenu, setSideMenu] = useState(false);
  const [filter, setFilter] = useState(true);
  const [searchProduct, setSearchProduct] = useState(true);
  const [selectedModal, setSelectedModal] = useState([]);
  const [searchCategory, setSearchCategory] = useState();

  const filterData = (visible) => {
    setSideMenu(visible);
    setFilter(false);
  };
  const applyData = () => {
    setSideMenu(false);
    setFilter(true);
  };
  const checkboxScrollForSingleArray = (value) => {
    const length = value.length;

    if (length > 0) {
      const arr = [length];
      const mainArray = [...sideMenuClicked, arr];
      setSideMenuClicked(mainArray);
    }

    if (length === 0) {
      const updatedSideMenuClicked = [...sideMenuClicked];
      updatedSideMenuClicked.pop();
      setSideMenuClicked(updatedSideMenuClicked);
    }
  };

  const redirectUrl = (value) => {
    //return window.open(`/url?${value}`, "_blank");
  };
  const openUrl = (value) => {
    ``; //return window.open(`/search?q=%22${value}%22`, "_self");
  };
  let { category } = useParams();

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
        <Header setSearchProduct={setSearchProduct} />
        <Breadcrumbs />
        <div className="row pop-box mt-4 justify-content-center">
          <div
            className="col-12 col-lg-3 col-md-3 col-sm-4 pad-rm filter"
            style={{
              display: sideMenu ? "" : "none",
              position: "fixed",
              zIndex: "99999",
              bottom: "40px",
              height: "95%",
            }}
          >
            <div className="col-12 col-lg-3 col-md-3 col-sm-8 tab-setup-left">
              <div className="tab-wrapper">
                <h1>Best Products OnSale & Live Deals in India.</h1>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <Tab.Container defaultActiveKey="model">
                        <Row>
                          <Col xs={4} sm={4} className="de-nn">
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="discount">
                                    Discount
                                  </Nav.Link>
                                </Nav.Item>

                                <Nav.Link eventKey="model">
                                  Best Products
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link eventKey="category">
                                  Category
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col xs={8} sm={8} className="right-box">
                            <Tab.Content>
                              <Tab.Pane eventKey="discount">
                                <div className="box">
                                  <MultiRange
                                    componentId="discountFilter"
                                    dataField="DISCOUNT_PERCENTAGE"
                                    title="Discount % "
                                    data={[
                                      {
                                        start: 80,
                                        end: 100,
                                        label: "80% to 100% Off",
                                      },
                                      {
                                        start: 60,
                                        end: 80,
                                        label: "60% to 80% Off",
                                      },
                                      {
                                        start: 40,
                                        end: 60,
                                        label: "40% to 60% Off",
                                      },
                                      {
                                        start: 0,
                                        end: 40,
                                        label: "Below 40% Off",
                                      },
                                    ]}
                                    sortBy="asc"
                                    defaultQuery={() => {
                                      return {
                                        query: {
                                          bool: {
                                            must: [
                                              { match: { CATEGORY: q } },
                                              { 
                                                "bool": {
                                                  "should": [
                                                    { "match": { "STATUS": 1 }},
                                                    { "match": { "STATUS": 2 }}
                                                  ]
                                                }
                                              }
                                            ],
                                          },
                                        },
                                      };
                                    }}
                                    react={{
                                      and: ["q", "ModelFilter"],
                                    }}
                                    filterLabel="Discount"
                                  />

                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="model">
                                <div className="box">
                                  <MultiList
                                    componentId="ModelFilter"
                                    dataField="MODEL.keyword"
                                    showSearch={true}
                                    showCount={true}
                                    title="Best Products"
                                    placeholder="Search Best Product"
                                    sortBy="asc"
                                    size={100}
                                    defaultQuery={() => {
                                      return {
                                        query: {
                                          bool: {
                                            must: [
                                              { match: { CATEGORY: q } },
                                              { 
                                                "bool": {
                                                  "should": [
                                                    { "match": { "STATUS": 1 }},
                                                    { "match": { "STATUS": 2 }}
                                                  ]
                                                }
                                              }
                                            ],
                                          },
                                        },
                                      };
                                    }}
                                    react={{
                                      and: [
                                        "q",
                                        "discountFilter",
                                        "CategorySensor",
                                      ],
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="category">
                                <div className="box">
                                  <MultiList
                                    componentId="CategorySensor"
                                    dataField="CATEGORY.keyword"
                                    showCount={true}
                                    showSearch={false}
                                    size={100}
                                    title="Category"
                                    placeholder="Search from Category"
                                    filterData={true}
                                    defaultQuery={() => {
                                      return {
                                        query: {
                                          bool: {
                                            must: [
                                              { match: { CATEGORY: q } },
                                              { 
                                                "bool": {
                                                  "should": [
                                                    { "match": { "STATUS": 1 }},
                                                    { "match": { "STATUS": 2 }}
                                                  ]
                                                }
                                              }
                                            ],
                                          },
                                        },
                                      };
                                    }}
                                    react={{
                                      and: [
                                        "q",
                                        "discountFilter",
                                        "ModelFilter",
                                      ],
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-md-block  col-md-3 col-12 col-lg-6  col-sm-12">
            <div className="pl-2">
              <p className="border-bottom pb-1">FILTER BY</p>
              <div className="border-bottom pb-3">
                <div className="box">
                  <MultiRange
                    componentId="discountFilter"
                    dataField="DISCOUNT_PERCENTAGE"
                    title="Discount % "
                    data={[
                      {
                        start: 80,
                        end: 100,
                        label: "80% to 100% Off",
                      },
                      {
                        start: 60,
                        end: 80,
                        label: "60% to 80% Off",
                      },
                      {
                        start: 40,
                        end: 60,
                        label: "40% to 60% Off",
                      },
                      {
                        start: 0,
                        end: 40,
                        label: "Below 40% Off",
                      },
                    ]}
                    defaultQuery={() => {
                      return {
                        query: {
                          bool: {
                            must: [
                              { match: { CATEGORY: q } },
                              { 
                                "bool": {
                                  "should": [
                                    { "match": { "STATUS": 1 }},
                                    { "match": { "STATUS": 2 }}
                                  ]
                                }
                              }
                            ],
                          },
                        },
                      };
                    }}
                    react={{
                      and: ["q", "ModelFilter", "StoreFilter"],
                    }}
                    filterLabel="Discount"
                  />
                </div>
                <div className="box">
                  <MultiList
                    componentId="ModelFilter"
                    dataField="MODEL.keyword"
                    showSearch={true}
                    showCount={true}
                    title="Best Products"
                    placeholder="Search Best Product"
                    sortBy="asc"
                    size={100}
                    defaultQuery={() => {
                      return {
                        query: {
                          bool: {
                            must: [
                              { match: { CATEGORY: q } },
                              { 
                                "bool": {
                                  "should": [
                                    { "match": { "STATUS": 1 }},
                                    { "match": { "STATUS": 2 }}
                                  ]
                                }
                              }
                            ],
                          },
                        },
                      };
                    }}
                    react={{
                      and: ["q", "discountFilter", "StoreFilter"],
                    }}
                  />
                </div>
                <div className="box">
                  <MultiList
                    componentId="StoreFilter"
                    dataField="SELLER_NAME.keyword"
                    showSearch={true}
                    showCount={true}
                    title="Store"
                    placeholder="Search from Store"
                    sortBy="asc"
                    size={100}
                    defaultQuery={() => {
                      return {
                        query: {
                          bool: {
                            must: [
                              { match: { CATEGORY: q } },
                              { 
                                "bool": {
                                  "should": [
                                    { "match": { "STATUS": 1 }},
                                    { "match": { "STATUS": 2 }}
                                  ]
                                }
                              }
                            ],
                          },
                        },
                      };
                    }}
                    react={{
                      and: ["q", "discountFilter", "ModelFilter"],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <button onClick={() => filterData(!sideMenu)} className="bestbtnss">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="result-icon"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" opacity="0.05"></path>
                <path
                  fill="#282C3F"
                  d="M3.749 7.508a.75.75 0 010-1.5h3.138a2.247 2.247 0 014.243 0h9.121a.75.75 0 010 1.5h-9.126a2.248 2.248 0 01-4.232 0H3.749zm13.373 9h3.129a.75.75 0 010 1.5h-3.135a2.247 2.247 0 01-4.231 0H3.749a.75.75 0 010-1.5h9.13a2.248 2.248 0 014.243 0z"
                ></path>
              </g>
            </svg>
            Filter
          </button>
          <div className="col-12 col-lg-9 col-md-9 col-sm-12  marginBottom">
            <ScrollButton/>
         {searchProduct ?   <ReactiveList
              componentId="categorySearch"
              showResultStats={true}
              dataField="CATEGORY"
              distinctField="COUPON.keyword"
              aggregationField="COUPON.keyword"
              size={10000}
              sortOptions={[
                {
                  dataField: "RATING_STAR",
                  sortBy: "desc",
                  label: "Sort by Popularity  \u00A0",
                },
                {
                  dataField: "DISCOUNT_PERCENTAGE",
                  sortBy: "desc",
                  label: "Sort by Discount %(High to Low)\u00A0 \u00A0",
                },
                {
                  dataField: "SELLER_NAME",
                  sortBy: "asc",
                  label: "Sort by A-Z\u00A0",
                },
                {
                  dataField: "DATE.keyword",
                  sortBy: "desc",
                  label: "Sort by Newest Deals \u00A0 \u00A0",
                },
              ]}
              defaultQuery={() => {
                
                return {
                  query: {
                    bool: {
                      must: [
                        { match: { "CATEGORY.keyword": q } },
                        { 
                          "bool": {
                            "should": [
                              { "match": { "STATUS": 1 }},
                              { "match": { "STATUS": 2 }}
                            ]
                          }
                        }
                      ],
                    },
                  },
                };
              }}
              react={{
                and: [
                  "category",
                  "discountFilter",
                  "StoreFilter",
                  "ModelFilter",
                  "CategorySensor",
                ],
              }}
              stream={true}
              scrollOnChange={false}
              loader
              render={({ data, aggregationData }) => {
                const resultSet = [];
                data.map((dataItem) => {
                  aggregationData.map((agItem) => {
                    if (dataItem.ID === agItem.ID) {
                      resultSet.push({
                        ...dataItem,
                        ...agItem,
                      });
                    }
                  });
                });
                return (
                  <ReactiveList.ResultCardsWrapper>
                    <div className="container row d-flex justify-content-center p-0">
                      {resultSet.map((item) => (
                           
                        <div className="d-flex  ml-2 col-lg-5 col-md-5 col-xl-3 col-sm-12 justify-content-center">
                          <ResultCard
                            key={item.id}
                            href={
                              item._doc_count > 1
                                ? "/details?q=%22" + item.TITLE + "%22"
                                : "/url?id=" + item.ID
                            }
                            target={item._doc_count > 1 ? "_self" : "_blank"}
                          >
                            <ResultCard.Description>
                              <span>
                                <div className="ps-capitalize">
                                { isNaN(item.COUPON) ? item.COUPON : null}

                                </div>
                              </span>
                              <span>
                                <div
                                  className="ps-compare-price"
                                  onClick={(e) => {
                                    window.location.href =
                                      item._doc_count > 1
                                        ? "/details?q=%22" +
                                          item.TITLE +
                                          "%22" +
                                          `&title=${item.MODEL}`
                                        : "/url?id=" + item.ID 
                                  }}
                                >
                                  {item._doc_count > 1
                                    ? "Compare Price ("
                                    : null}{" "}
                                  <span className="item-count">
                                    {item._doc_count > 1
                                      ? item._doc_count
                                      : null}
                                  </span>{" "}
                                  <span className="for-store">
                                    {item._doc_count > 1 ? "Stores)" : null}
                                  </span>
                                  <div> </div>......
                                </div>
                              </span>
                            </ResultCard.Description>
                            {item.IMAGE_URL &&
                            (item.IMAGE_URL.includes("http:") ||
                              item.IMAGE_URL.includes("http:")) ? null : ( // <ResultCard.Image src={`https:${item.IMAGE_URL}`} />
                              <ResultCard.Image src={item.IMAGE_URL} />
                            )}
                            <ResultCard.Description>
                              {item.BRAND ? (
                                <div className="text-center">
                                  Brand: <b>{item.BRAND}</b>
                                </div>
                              ) : (
                                <div>
                                  <span className="align-left">
                                    <span className="badge  badge-success">
                                      {item.RATING_STAR
                                        ? rating(item.RATING_STAR) + "*"
                                        : null}
                                    </span>
                                    <span className="small card-subtitle mb-2 text-muted">
                                      {item.RATING_COUNT
                                        ? "(" + item.RATING_COUNT + ")"
                                        : null}
                                    </span>
                                  </span>

                                  <span className="float-right">
                                    @
                                    <span className="authors-list mb-2 text-muted">
                                      {item.SELLER_NAME}
                                    </span>
                                  </span>
                                </div>
                              )}
                            </ResultCard.Description>

                            <ResultCard.Title>
                              {/* <small className="mt-1">{item.TITLE}</small> */}
                              <div
                                className="text-wrap mb-2"
                                dangerouslySetInnerHTML={{
                                  __html: item.TITLE,
                                }}
                              />
                              <span>
                                <br />
                              </span>
                            </ResultCard.Title>

                            <ResultCard.Description>
                              <div>
                                <span>
                                  <small>
                                    <strike>
                                      {item.ORIGINAL_PRICE
                                        ? numberFormat(item.ORIGINAL_PRICE)
                                        : null}
                                    </strike>
                                  </small>
                                  <b className="ml-2">
                                    {item.OFFER_PRICE
                                      ? numberFormat(item.OFFER_PRICE)
                                      : null}
                                  </b>
                                </span>
                                <span className="float-right">
                                  <div className="badge  badge-warning">
                                    <span>(</span>
                                    <span>
                                      {item.DISCOUNT_PERCENTAGE
                                        ? item.DISCOUNT_PERCENTAGE + "% OFF"
                                        : "Get-Price"}
                                    </span>
                                    <span>)</span>
                                  </div>
                                </span>
                                {item.CASHBACK_AMOUNT ? (
                                  <div className="text-center border my-2 bg-warning">
                                    <span>
                                      {" "}
                                      Cashback{" "}
                                      {numberFormat(item.CASHBACK_AMOUNT)}{" "}
                                    </span>
                                  </div>
                                ) : null}
                                {item.FINAL_PRICE ? (
                                  <div className="text-center text-info">
                                    <span>
                                      {" "}
                                      Final Price{" "}
                                      <b> {numberFormat(item.FINAL_PRICE)}</b>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </ResultCard.Description>
                          </ResultCard>
                        </div>
                      ))}
                    </div>
                  </ReactiveList.ResultCardsWrapper>
                );
              }}
            /> : "" } 
          </div>
        </div>
        <footer className="page-footer  center-on-small-only pt-0">
          <div className="container">
            <div className="row pt-5 mb-3 text-center d-flex justify-content-center">
              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/">Home</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/buyers-guide/index.html">Buyer's Guide</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/about-us">About Us</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/privacy-and-policy">Privacy Policy</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/resources/public/contactus.html">Contact Us</a>
                </h6>
              </div>
            </div>
          </div>
          <div className="footer-copyright text-center">
            <div className="container-fluid">
              © Copyright 2025 PlanetOnSale. All Rights Reserved. All content,
              trademarks and logos are copyright of their respective owners.
            </div>
          </div>
          <br></br>
          <div className="footer-copyright text-center">
            <div className="container-fluid">
              <b>Disclosure:</b> Many links on the PlanetOnSale site are
              affiliate links. 
              {/* This site is a participant in the Amazon
              Associates Program, an affiliate advertising program designed to
              provide a means for sites to earn advertising fees by linking to
              amazon.in */}
            </div>
          </div>
        </footer>
        {/* ... rest of your JSX */}
      </ReactiveBase>
    </div>
  );
};

export default CategoryDetalis;
