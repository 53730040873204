import React, { useState, useEffect } from "react";
import Footer from "../layout/Footer";
import Breadcrumbs from "../layout/Breadcrumb";
import Menu from "../Mobile/Menu";
import { Link } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import "../App.css";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Header from "../layout/Header";

import {
  ReactiveBase,
  MultiList,
  ReactiveList,
  SelectedFilters,
  ResultCard,
} from "@appbaseio/reactivesearch";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { elasticSearchUrl } from "../common/util";
import ScrollButton from "../ScrollButton";

export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

const ViewAllBestProduct = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [checkIsMobile, setCheckIsMobile] = useState(false);
  const [filter, setFilter] = useState(true);
  const [sideMenuClicked, setSideMenuClicked] = useState([]);
  const [sideMenu, setSideMenu] = useState(false);
  const [sidePriceMenu, setSidePriceMenu] = useState(false);
  const [searchProduct,setSearchProduct] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobile(false);
        setCheckIsMobile(true);
      } else {
        setIsMobile(true);
        setCheckIsMobile(true);
      }
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filterData = (visible) => {
    setSideMenu(visible);
    setFilter(false);
  };
  const filterPriceData = (visible) => {
    setSidePriceMenu(visible);
  };

  const applyData = () => {
    setSideMenu(false);
    setFilter(true);
  };

  const checkboxScrollForSingleArray = (value) => {
    const length = value.length;

    if (length > 0) {
      const arr = [length];
      const mainArray = [...sideMenuClicked, arr];
      setSideMenuClicked(mainArray);
    }

    if (length === 0) {
      const updatedSideMenuClicked = [...sideMenuClicked];
      updatedSideMenuClicked.pop();
      setSideMenuClicked(updatedSideMenuClicked);
    }
  };

  const redirectUrl = (value) => {
    //return window.open(`/url?${value}`, "_blank");
  };
  const openUrl = (value) => {
    ``; //return window.open(`/search?q=%22${value}%22`, "_self");
  };

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="_doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
        <Header setSearchProduct={setSearchProduct}/>
        <div>
          <Breadcrumbs />
        </div>
        <div className="row pop-box mt-4 justify-content-center">
          <div
            className="col-12 col-lg-3 col-md-3 col-sm-4 pad-rm filter"
            style={{
              display: sideMenu ? "" : "none",
              position: "fixed",
              zIndex: "99999",
              bottom: "40px",
              height: "95%",
            }}
          >
            <div className="col-12 col-lg-3 col-md-3 col-sm-8 tab-setup-left">
              <div className="tab-wrapper">
                <h1>Best Products OnSale & Live Deals in India.</h1>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <Tab.Container defaultActiveKey="model">
                        <Row>
                          <Col xs={4} sm={4} className="de-nn">
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="model">
                                  Best Products
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link eventKey="category">
                                  Category
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col xs={8} sm={8} className="right-box">
                            <Tab.Content>
                              <Tab.Pane eventKey="model">
                                <div className="box">
                                  <MultiList
                                    componentId="ModelFilter"
                                    dataField="MODEL.keyword"
                                    showSearch={true}
                                    showCount={true}
                                    title="Best Products"
                                    placeholder="Search Best Product"
                                    sortBy="asc"
                                    size={100}
                                    defaultQuery={() => {
                                      return { 
                                        "query": {
                                          "bool": {
                                            "must": [
                                              { "match": { "IS_HOT": "Y" }},
                                              { 
                                                "bool": {
                                                  "should": [
                                                    { "match": { "STATUS": 1 }},
                                                    { "match": { "STATUS": 2 }}
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      };                  
                                    }}
                                    react={{
                                      and: ["q","CategorySensor"]
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="category">
                                <div className="box">
                                  <MultiList
                                    componentId="CategorySensor"
                                    dataField="CATEGORY.keyword"
                                    showCount={true}
                                    showSearch={false}
                                    size={20}
                                    title="Category"
                                    placeholder="Search from Category"
                                    filterData={true}
                                    defaultQuery={() => {
                                      return { 
                                        "query": {
                                          "bool": {
                                            "must": [
                                              { "match": { "IS_HOT": "Y" }},
                                              { 
                                                "bool": {
                                                  "should": [
                                                    { "match": { "STATUS": 1 }},
                                                    { "match": { "STATUS": 2 }}
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      };                  
                                    }}
                                    react={{
                                      and: ["q","ModelFilter"]
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-md-block  col-md-3 col-12 col-lg-6  col-sm-12">
            <div className="pl-2">
              <p className="border-bottom pb-1">FILTER BY</p>
              <div className="border-bottom pb-3">
                <div className="box">
                  <MultiList
                    componentId="ModelFilter"
                    dataField="MODEL.keyword"
                    showSearch={true}
                    showCount={true}
                    title="Best Products"
                    placeholder="Search Best Product"
                    sortBy="asc"
                    size={100}
                    defaultQuery={() => {
                      return { 
                        "query": {
                          "bool": {
                            "must": [
                              { "match": { "IS_HOT": "Y" }},
                              { 
                                "bool": {
                                  "should": [
                                    { "match": { "STATUS": 1 }},
                                    { "match": { "STATUS": 2 }}
                                  ]
                                }
                              }
                            ]
                          }
                        }
                      };                  
                    }}
                    react={{
                      and: ["q","CategorySensor"]
                    }}
                  />
                </div>

                <div className="box">
                  <MultiList
                    componentId="CategorySensor"
                    dataField="CATEGORY.keyword"
                    showCount={true}
                    showSearch={false}
                    size={20}
                    title="Category"
                    placeholder="Search from Category"
                    filterData={true}
                    defaultQuery={() => {
                      return { 
                        "query": {
                          "bool": {
                            "must": [
                              { "match": { "IS_HOT": "Y" }},
                              { 
                                "bool": {
                                  "should": [
                                    { "match": { "STATUS": 1 }},
                                    { "match": { "STATUS": 2 }}
                                  ]
                                }
                              }
                            ]
                          }
                        }
                      };                  
                    }}
                    react={{
                      and: ["q","ModelFilter"]
                    }}
                  />
                  <button
                    onClick={() => {
                      applyData();
                    }}
                    className="bestaply"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button onClick={() => filterData(!sideMenu)} className="bestbtnss">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="result-icon"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" opacity="0.05"></path>
                <path
                  fill="#282C3F"
                  d="M3.749 7.508a.75.75 0 010-1.5h3.138a2.247 2.247 0 014.243 0h9.121a.75.75 0 010 1.5h-9.126a2.248 2.248 0 01-4.232 0H3.749zm13.373 9h3.129a.75.75 0 010 1.5h-3.135a2.247 2.247 0 01-4.231 0H3.749a.75.75 0 010-1.5h9.13a2.248 2.248 0 014.243 0z"
                ></path>
              </g>
            </svg>
            Filter
          </button>
          {/* <button
            onClick={() => filterPriceData(!sidePriceMenu)}
            className="bestbtnss sortBtn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M8 16H4l6 6V2H8zm6-11v17h2V8h4l-6-6z"
              />
            </svg>
            Sort
          </button> */}
          <div className=" col-sm-12 col-md-8 col-lg-9 justify-content-center">
            <h4 className="text-center"> BEST PRODUCTS </h4>
            <SelectedFilters />
            <ScrollButton/>
            
            
            <ReactiveList
              componentId="BestProducts"
              showResultStats={true}
              dataField="COUPON.keyword"
              aggregationField="COUPON.keyword"
              size={10000}
              sortBy="asc"
              defaultQuery={() => {
                return { 
                  "query": {
                    "bool": {
                      "must": [
                        { "match": { "IS_HOT": "Y" }},
                        { 
                          "bool": {
                            "should": [
                              { "match": { "STATUS": 1 }},
                              { "match": { "STATUS": 2 }}
                            ]
                          }
                        }
                      ]
                    }
                  }
                };                  
              }}
              pagination={false}
              sortOptions={[
                {
                  dataField: "COUPON.keyword",
                  sortBy: "asc",
                  label: "Sort by A-Z\u00A0",
                },
                {
                  dataField: "RATING_STAR",
                  sortBy: "desc",
                  label: "Sort by Poularity \u00A0",
                },
                {
                  dataField: "DATE.keyword",
                  sortBy: "desc",
                  label: "Sort by Newest\u00A0 \u00A0",
                },
              ]}
             
              stream={true}
              scrollOnChange={false}
              loader
              react={{
                and: ["ModelFilter", "CategorySensor", "bestProducts","q"],
              }}
              
              render={({ data, aggregationData }) => {
                const resultSet = [];
                data.map((dataItem) => {
                  aggregationData.map((agItem) => {
                    if (dataItem.ID === agItem.ID) {
                      resultSet.push({
                        ...dataItem,
                        ...agItem,
                      });
                    }
                  });
                });
                return (
                  <ReactiveList.ResultCardsWrapper>
                    <div className="container row d-flex justify-content-center p-0">
                      {resultSet.map((item,index) => (
                        <div className="d-flex  ml-2 col-lg-4 col-md-4 col-xl-3 col-sm-12 justify-content-center" key={index}>
                          <ResultCard
                            key={item.id}
                            href={
                              item._doc_count > 1
                                ? "/details?q=%22" + item.TITLE + "%22"
                                : "/url?id=" + item.ID 
                            }
                            target={item._doc_count > 1 ? "_self" : "_blank"} 
                          >
                            <ResultCard.Description>
                              <span>
                                <div className="ps-capitalize">
                                  { item.COUPON }
                                </div>
                              </span>
                              <span>
                                <div
                                  className="ps-compare-price"
                                  onClick={(e) => {
                                    window.location.href =
                                      item._doc_count > 1
                                        ? "/details?q=%22" +
                                          item.TITLE +
                                          "%22" +
                                          `&title=${item.MODEL}`
                                         : "/url?id=" + item.ID 
                                  }}
                                >
                                  {item._doc_count > 1
                                    ? "Compare Price ("
                                    : null}{" "}
                                  <span className="item-count">
                                    {item._doc_count > 1
                                      ? item._doc_count
                                      : null}
                                  </span>{" "}
                                  <span className="for-store">
                                    {item._doc_count > 1 ? "Stores)" : null}
                                  </span>
                                  <div> </div>......
                                </div>
                              </span>
                            </ResultCard.Description>
                            {item.IMAGE_URL &&
                            (item.IMAGE_URL.includes("http:") ||
                              item.IMAGE_URL.includes("http:")) ? null : ( // <ResultCard.Image src={`https:${item.IMAGE_URL}`} />
                              <ResultCard.Image src={item.IMAGE_URL} />
                            )}
                            <ResultCard.Description>
                              {item.BRAND ? (
                                <div className="text-center">
                                  Brand: <b>{item.BRAND}</b>
                                </div>
                              ) : (
                                <div>
                                  <span className="align-left">
                                    <span className="badge  badge-success">
                                      {item.RATING_STAR
                                        ? rating(item.RATING_STAR) + "*"
                                        : null}
                                    </span>
                                    <span className="small card-subtitle mb-2 text-muted">
                                      {item.RATING_COUNT
                                        ? "(" + item.RATING_COUNT + ")"
                                        : null}
                                    </span>
                                  </span>

                                  <span className="float-right">
                                    @<span className="authors-list mb-2 text-muted">
                                       {item.SELLER_NAME }
                                    </span>
                                  </span>
                                </div>
                              )}
                            </ResultCard.Description>

                            <ResultCard.Title>
                              {/* <small className="mt-1">{item.TITLE}</small> */}
                              <div
                                className="text-wrap mb-2"
                                dangerouslySetInnerHTML={{
                                  __html: item.TITLE,
                                }}
                              />
                              <span>
                                <br /> 
                              </span>
                            </ResultCard.Title>

                            <ResultCard.Description>
                              <div>
                                <span>
                                  <small>
                                    <strike>
                                      {item.ORIGINAL_PRICE
                                        ? numberFormat(item.ORIGINAL_PRICE)
                                        : null}
                                    </strike>
                                  </small>
                                  <b className="ml-2">
                                    {item.OFFER_PRICE
                                      ? numberFormat(item.OFFER_PRICE)
                                      : null}
                                  </b>
                                </span>
                                <span className="float-right">
                                  <div className="badge  badge-warning">
                                    <span>(</span>
                                    <span>
                                      {item.DISCOUNT_PERCENTAGE
                                        ? item.DISCOUNT_PERCENTAGE + "% OFF"
                                        : "Get-Price"}
                                    </span>
                                    <span>)</span>
                                  </div>
                                </span>
                                {item.CASHBACK_AMOUNT ? (
                                  <div className="text-center border my-2 bg-warning">
                                 <span> Cashback {numberFormat(item.CASHBACK_AMOUNT)}  </span>
                                  </div>
                                ) : null}
                                {item.FINAL_PRICE ? (
                                  <div className="text-center text-info">
                                     <span> Final Price <b> {numberFormat(item.FINAL_PRICE)}</b></span>
                                  </div>
                                ) : null}
                              </div>
                            </ResultCard.Description>
                          </ResultCard>
                        </div>
                      ))}
                    </div>
                  </ReactiveList.ResultCardsWrapper>
                );
              }}
            /> 
          </div>
        </div>

        <Footer />
        <br></br>
      </ReactiveBase>
    </div>
  );
};

export default ViewAllBestProduct;
