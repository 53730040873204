import React, { useState, useEffect } from "react";
import {
  ReactiveBase,
  SearchBox,
  ReactiveList,
  ResultCard
} from "@appbaseio/reactivesearch";
import ".././App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Responsive from "react-responsive-decorator";
import Menu from "../Mobile/Menu";
// import BootstrapCarouselDynamicDummy from ".././BootstrapCarouselDynamicDummy";
import { withRouter,useLocation } from "react-router-dom";
import ScrollButton from "./ScrollButtonSearch";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ApiService from "../services/Auth";
import { IoIosArrowDown } from "react-icons/io";
import Breadcrumbs from "../layout/Breadcrumb";
import { elasticSearchUrl } from "./util";
import { getParameterByName } from "../App";
import { buyersGuideLinks } from "./common";
import Modal from "react-bootstrap/Modal";
import Header from "../layout/Header";
export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

export const scrollModify = { bottom: "50px" };

const ProductDetails = () => {
  const redirectUrl = (value) => {
    console.log(value);
    return window.open(`/url?${value}`, "_blank");
  };
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get("q");
  const history = useHistory();
  const [isLogedin, setIsLogedin] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [searchProduct, setSearchProduct] = useState(true);
  const [record, setRecord] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const token = localStorage.getItem("token");

 
  const openUrl = (value) => {
    ``; //return window.open(`/search?q=%22${value}%22`, "_self");
  };
  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
      <Header  setSearchProduct={setSearchProduct}/>

        {/* <Breadcrumbs /> */}
        <div className="row pop-box mt-4 justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12   marginBottom">
            <ScrollButton />
            <ReactiveList
              componentId="SearchResult"
              dataField="TITLE"
              distinctField="COUPON.keyword"
              aggregationField="COUPON.keyword"
              size={1000}
              pagination={false}
              scrollOnChange={true}
              loader
              defaultQuery={() => {
              if (searchProduct) {
                return { 
                  "query": {
                    "bool": {
                      "must": [
                        { 
                          "bool": {
                            "should": [
                              { "match": { "STATUS": 1 }},
                              { "match": { "STATUS": 2 }}
                            ]
                          }
                        }
                      ]
                    }
                  }
                }; 
              } else {
                return {
                  query: {}
                };
              }                
              }}
             
              react={{
                and: [
                  "q",
                  "ratingsFilter",
                  "BestSellingSensor",
                  "StoreSensor",
                  "authorFilter",
                  "CategorySensor",
                  "BrandFilter",
                  "StoreFilter",
                  "DateRangeSensor",
                  "PriceSensor",
                  "PriceInputSensor",
                  "TrendingSensor",
                  "ModelFilter",
                ],
              }}
              render={({ data, aggregationData }) => {
                const resultSet = [];
                data.map((dataItem) => {
                  aggregationData.map((agItem) => {
                    if (dataItem.ID === agItem.ID) {
                      resultSet.push({
                        ...dataItem,
                        ...agItem,
                      });
                    }
                  }); 
                });
                return (
                  <ReactiveList.ResultCardsWrapper>  
                      <div className="container-fluid row justify-content-center">
                        {resultSet && resultSet.map((item) => (
                          <div className=" col-xs-12  col-sm-12 col-md-4 col-lg-3">
                            <ResultCard
                              key={item.id}
                              href={
                                item._doc_count > 1 
                                  ? "/details?q=%22" + item.TITLE + "%22"
                                  : "/url?id=" + item.ID
                              }
                              target={item._doc_count > 1 ? "_self" : "_blank"}
                              // onClick={(e) => {
                              //   item._doc_count > 1
                              //     ? openUrl(item.TITLE)
                              //     : redirectUrl(item.OFFER_URL);
                              // }}
                            >
                              <ResultCard.Description>
                                <span>
                                  <div className="ps-capitalize">
                                  {isNaN(item.COUPON) ? item.COUPON : null}
                                  </div>
                                </span>
                                <span>
                                  <div
                                    className="ps-compare-price"
                                    onClick={(e) => {
                                      window.location.href =
                                        item._doc_count > 1
                                          ? "/details?q=%22" +
                                            item.TITLE +
                                            "%22" +
                                            `&title=${item.MODEL}`
                                            : "/url?id=" + item.ID +
                                            `&title=${item.MODEL}`;
                                    }}
                                  >
                                    {item._doc_count > 1
                                      ? "Compare Price ("
                                      : null}{" "}
                                    <span className="item-count">
                                      {item._doc_count > 1
                                        ? item._doc_count
                                        : null}
                                    </span>{" "}
                                    <span className="for-store">
                                      {item._doc_count > 1 ? "Stores)" : null}
                                    </span>
                                    <div> </div>......
                                  </div>
                                </span>
                              </ResultCard.Description>
                              {item.IMAGE_URL &&
                              (item.IMAGE_URL.includes("http:") ||
                                item.IMAGE_URL.includes("http:")) ? null : ( // <ResultCard.Image src={`https:${item.IMAGE_URL}`} />
                                <ResultCard.Image src={item.IMAGE_URL} />
                              )}
                              <ResultCard.Description>
                                {item.BRAND ? (
                                  <div className="text-center">
                                    Brand: <b>{item.BRAND}</b>
                                  </div>
                                ) : (
                                  <div>
                                    <span className="align-left">
                                      <span className="badge  badge-success">
                                        {item.RATING_STAR
                                          ? rating(item.RATING_STAR) + "*"
                                          : null}{" "}
                                      </span>
                                      <span className="small card-subtitle mb-2 text-muted">
                                        {" "}
                                        {item.RATING_COUNT
                                          ? "(" + item.RATING_COUNT + ")"
                                          : null}
                                      </span>
                                    </span>

                                    <span className="float-right">
                                      @{" "}
                                      <span className="authors-list mb-2 text-muted">
                                        {item.SELLER_NAME}
                                      </span>
                                    </span>
                                  </div>
                                )}
                              </ResultCard.Description>

                              <ResultCard.Title>
                                <div
                                  className="text-wrap"
                                  dangerouslySetInnerHTML={{
                                    __html: item.TITLE,
                                  }}
                                />
                                <span>
                                  <br />
                                </span>
                              </ResultCard.Title>

                              <ResultCard.Description>
                                <div>
                                  <span>
                                    <b>
                                      {" "}
                                      {item.OFFER_PRICE
                                        ? numberFormat(item.OFFER_PRICE)
                                        : null}{" "}
                                    </b>
                                    <strike>
                                      {" "}
                                      {item.ORIGINAL_PRICE
                                        ? numberFormat(item.ORIGINAL_PRICE)
                                        : null}
                                    </strike>
                                  </span>
                                  <span className="float-right">
                                    <div className="badge  badge-warning">
                                      <span>
                                        {item.DISCOUNT_PERCENTAGE
                                          ? item.DISCOUNT_PERCENTAGE + "% OFF"
                                          : "Get-Price"}
                                      </span>
                                    </div>
                                  </span>
                                  {item.CASHBACK_AMOUNT ? <div className="text-center border mt-1 bg-light">
                                    <b> + {numberFormat(item.CASHBACK_AMOUNT)} Cashback</b>
                                </div> : null }
                                {item.FINAL_PRICE ?   <div className="text-center mt-1">
                                   <b> Final Price { numberFormat(item.FINAL_PRICE)} </b>                                      
                                </div> : null }
                                </div>
                              </ResultCard.Description>
                            </ResultCard>
                          </div>
                        ))}
                      </div>
                    
                  </ReactiveList.ResultCardsWrapper> 
                );
              }}
            />
          </div>
        </div>
        <footer className="page-footer  center-on-small-only pt-0">
          <div className="container">
            <div className="row pt-5 mb-3 text-center d-flex justify-content-center">
              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/">Home</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/buyers-guide/index.html">Buyer's Guide</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/about-us">About Us</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/privacy-and-policy">Privacy Policy</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/resources/public/contactus.html">Contact Us</a>
                </h6>
              </div>
            </div>
          </div>
          <div className="footer-copyright text-center">
            <div className="container-fluid">
              © Copyright 2025 PlanetOnSale. All Rights Reserved. All content,
              trademarks and logos are copyright of their respective owners.
            </div>
          </div>
          <br></br>
          <div className="footer-copyright text-center">
            <div className="container-fluid">
              <b>Disclosure:</b> Many links on the PlanetOnSale site are
              affiliate links.
               {/* This site is a participant in the Amazon
              Associates Program, an affiliate advertising program designed to
              provide a means for sites to earn advertising fees by linking to
              amazon.in */}
            </div>
          </div>
        </footer>
        {/* ... rest of your JSX */}
      </ReactiveBase>

    </div>
  );
};

export default Responsive(withRouter(ProductDetails));