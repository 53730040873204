export const buyersGuideLinks = {
  "Air fryers": "best-air-fryer-in-india.html",
  "Air purifiers": "best-air-purifier-in-india.html",
  "BP monitor machines": "best-blood-pressure-monitoring-machine-in-india.html",
  "Bluetooth speakers": "best-bluetooth-speaker-in-india.html",
  "Coccyx seat cushions":
    "best-coccyx-seat-cushion-for-tailbone-pain-relief-in-India.html",
  Dishwashers: "best-dishwasher-in-india.html",
  Earbuds: "best-earbud-in-india.html",
  Earphones: "best-wired-earphone-in-india.html",
  "File folders": "best-file-folder-in-india.html",
  "Front-load washing machines":
    "best-front-load-washing-machine-in-india.html",
  Glucometers: "best-glucometer-for-home-use-in-india.html",
  "IR thermometers": "best-ir-thermometer-for-body-temperature-in-india.html",
  "Induction cooktops": "best-induction-cooktop-in-india.html",
  "Laptop backpacks": "best-laptop-backpack-in-india.html",
  "Microwave ovens": "best-microwave-oven-in-india.html",
  "Office chairs": "best-office-chair-for-work-from-home-in-india.html",
  "Power banks": "best-power-bank-with-fast-charging-in-india.html",
  "Pulse oximeters": "best-pulse-oximeter-for-home-use-in-india.html",
  "Router UPS": "best-router-ups-in-india.html",
  SSDs: "best-portable-external-ssd-in-india.html",
  "Spin mops": "best-spin-mop-with-bucket-in-india.html",
  "Steam inhalers": "best-steam-inhaler-for-cold-and-cough-in-india.html",
  "TDS meters": "best-tds-meter-for-water-testing-in-india.html",
  "Tablets for students": "best-tablet-for-students-in-india.html",
  Toys: "best-toys-in-india.html",
  Webcams: "best-webcam-for-pc-or-laptop-in-india.html",
  "WiFi range extenders": "best-wifi-range-extender-in-india.html",
  "Wired headphones": "best-wired-headphone-in-india.html",
  "Computer keyboards": "best-computer-keyboard-in-india.html",
  "Computer monitors (24-inch)": "best-24-inch-monitor-in-india.html",
  "Height-adjustable computer desks":
    "best-height-adjustable-computer-desk-in-india.html",
  "Top-load washing machines": "best-top-load-washing-machine-in-india.html",
  "Kids' smartwatches": "best-kids-smartwatch-with-GPS-in-india.html",
};
