
// import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
// import { ResultCard } from "@appbaseio/reactivesearch";
// import { MdArrowForwardIos } from "react-icons/md";
// import { Link } from "react-router-dom";
// import { isTablet, isDesktop } from "react-device-detect";
// import {
//   MdOutlineArrowBackIos,
//   MdOutlineArrowForwardIos,
// } from "react-icons/md";

// export const rating = (value) =>
//   new Intl.NumberFormat("en-IN", {}).format(value);

// export const numberFormat = (value) =>
//   new Intl.NumberFormat("en-IN", {
//     style: "currency",
//     currency: "INR",
//   }).format(value);

// const CategoryDeals = ({ data }) => {
//   const [page, setPage] = useState(1);
//   const total = data.length;
//   const pageSize = isDesktop ? 5 : isTablet ? 3 : 1;

//   const history = useHistory();
//   const redirectUrl = (value) => {
//     //return window.open(`/url?${value}`, "_blank");
//   };

//   const openUrl = (value) => {
//     ``; //return window.open(`/search?q=%22${value}%22`, "_self");
//   };

//   const handleViewAllClick = () => {
//     history.push("/deals-by-category");
//   };

//   // Calculate total pages
//   const totalPages = Math.ceil(total / pageSize);

//   // Calculate start and end indexes
//   const startIndex = (page - 1) * pageSize;
//   const endIndex = Math.min(startIndex + pageSize - 1, total - 1);

//   // Handle page change
//   const handlePageChange = (newPage) => {
//     // Ensure newPage is within valid range
//     if (newPage >= 1 && newPage <= totalPages) {
//       setPage(newPage);
//     }
//   };

//   const newList = data;

//   return (
//     <div className="container-fluid">
//       <div className=" d-flex justify-content-between">
//         <div className="UINames">
//           <h5 className="my-3">DEALS BY CATEGORY</h5>
//         </div>
//         <div>
//           <h5 className="my-3" onClick={handleViewAllClick}>
//             <Link to="/deals-by-category" className="text-dark">
//               VIEW ALL <MdArrowForwardIos />
//             </Link>
//           </h5>
//         </div>
//       </div>
//       <div className="carousel slide">
//         <div className="HomePageView container-fluid row">
//           {newList.length > 0 &&
//             newList.slice(startIndex, endIndex + 1).map((item) => (
//               <div
//                 className="col-xs-12  col-sm-12 col-md-4 col-lg-3 mt-md-0"
//                 key={item.ID}
//               >
//                 <ResultCard
//                    href={"/category?q=" + encodeURIComponent(item.CATEGORY)}
//                   key={item.id}
//                   onClick={() => { window.location.href = "/category?q=" + encodeURIComponent(item.CATEGORY); }}
               
//                  >
//                   <ResultCard.Description>
                   
//                   </ResultCard.Description>

//                   <ResultCard.Image src={item.CATEGORY_IMAGE_URL} />
//                   <ResultCard.Description>
                    
//                   </ResultCard.Description>

//                   <ResultCard.Title>
//                     <div
//                       className="text-center"
//                     > <h6>{item.CATEGORY}</h6>
//                     </div>
//                     <span>
//                       <br />
//                     </span>
//                   </ResultCard.Title>
//                 </ResultCard>
//               </div>
//             ))}
//         </div>
//         <div>
//           {/* Display pagination buttons */}
//           <button
//             type="button"
//             className="btn btn-warning  prev5"
//             onClick={() => handlePageChange(page - 1)}
//             disabled={page === 1}
//           >
//             <MdOutlineArrowBackIos />
//           </button>

//           <button
//             type="button"
//             className="mb-2 btn btn-warning next5"
//             onClick={() => handlePageChange(page + 1)}
//             disabled={page === totalPages}
//           >
//             <MdOutlineArrowForwardIos />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

//export default CategoryDeals;

import React from "react";
import { useHistory } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import { ResultCard } from "@appbaseio/reactivesearch";
import "../App.css";
export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

  

const CategoryDeals = ({ data }) => {
  const history = useHistory();
  const redirectUrl = (value) => {
    //return window.open(`/url?${value}`, "_blank");
  };

  const openUrl = (value) => {
    ``; //return window.open(`/search?q=%22${value}%22`, "_self");
  };

  const handleViewAllClick = () => {
    history.push("/deals-by-category");
  };

  return (
    <div className="container-fluid">
      <div className=" d-flex justify-content-between">
        <div className="UINames">
          <h5 className="my-3">DEALS BY CATEGORY</h5>
        </div>
        <div>
          <h5 className="my-3" onClick={handleViewAllClick}>
            <Link to="/deals-by-category" className="text-dark">
              VIEW ALL <MdArrowForwardIos />
            </Link>
          </h5>
        </div>
      </div>
      <div className="carousel slide">
        <div className="HomePageView container-fluid row">
          {
            data.map((item) => (
              <div
                className="col-xs-12  col-sm-12 col-md-4 col-lg-3 mt-md-0"
                key={item.ID}
              >
                <ResultCard
                   key={item.id}
                   href={"/category?q=" + encodeURIComponent(item.CATEGORY)}
                   target={item._doc_count > 1 ? "_self" : "_blank"}
                  // onClick={() => { window.location.href = "/category?q=" + encodeURIComponent(item.CATEGORY); }}
                 >
              
                  <ResultCard.Description>
                   
                  </ResultCard.Description>

                  <ResultCard.Image src={item.CATEGORY_IMAGE_URL} />
                  <ResultCard.Description>
                    
                  </ResultCard.Description>

                  <ResultCard.Title>
                    <div
                      className="text-center"
                    > <h6>{item.CATEGORY}</h6>
                    </div>
                    <span>
                      <br />
                    </span>
                  </ResultCard.Title>
                </ResultCard>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryDeals;

