import React from "react";
import Form from "react-bootstrap/Form";
import "../App.css";
import { useHistory } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import ReactApexCharts from "react-apexcharts";
import Header from "../layout/Header";
import { ReactiveBase } from "@appbaseio/reactivesearch";
import "../App.css";
import Breadcrumbs from "../layout/Breadcrumb";
import { elasticSearchUrl } from "../common/util";
function WithdrawMoney() {
  const history = useHistory();


  const confirmedCashback = () =>{
    history.push("confirm-cashback");
  }
  const series = [25, 75];
  const options = {
    chart: {
      type: "donut",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: ['Cashback + Referral', 'Available Rewards '],

  };

  return (
    <div>
       <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="_doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
      <Header />
      <div>
       <Breadcrumbs/>
      </div>
      <div className="container mt-3">
        <div className="row justify-content-center vertically">
          <div className="col-lg-9">
            <Form className="shadow p-3 rounded">
              <div className="col-lg-12">
                <div className="row ">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <div>
                      <ReactApexCharts
                        options={options}
                        series={series}
                        type="donut"
                        width={380}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div class="col-md-6 border-right">
                    <div className="d-flex align-items-center">
                      <FaRupeeSign size={22} />
                      <h4>29.30</h4>
                    </div>
                    <div>
                      <p>
                        Cashback Available For Payment <br /> (Cashback +
                        Referral)
                      </p>
                    </div>
                    <div className="dottedBorder mt-4"></div>
                    <div className="mt-3 mb-3">
                      <h6>
                        What's This <BsQuestionCircle size={15} />
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="d-flex align-items-center">
                      <FaRupeeSign size={22} />
                      <h4>70</h4>
                    </div>
                    <p>Rewards Available For Payment</p>
                    <div className="dottedBorder mt-5"></div>
                    <div className="mt-3 mb-3">
                      <h6>
                        What's This <BsQuestionCircle size={15} />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-5 justify-content-center col-lg-12">
                <Button
                  className="col-lg-4 text-white"
                  variant="warning"
                  size="lg"
                  type="submit"
                  onClick={confirmedCashback}
                >
                  Withdraw
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </ReactiveBase>
    </div>
  );
}

export default WithdrawMoney;
