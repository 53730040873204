import React, { useState, useEffect } from "react";
import { isTablet, isDesktop, isMobile } from "react-device-detect";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import {
  ReactiveBase,
  ResultCard,
  ReactiveList,
} from "@appbaseio/reactivesearch";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Responsive from "react-responsive-decorator";
import { withRouter } from "react-router-dom";
import Header from "./layout/Header";
import BanerCarousel from "./layout/Carousel";
import Footer from "./layout/Footer";
import TrendingDeals from "./trending/TrendingDeals";
import CashBackStore from "./cashback/CashBackStore";
import BestProduct from "./best-product/BestProduct";
import CategoryDeals from "./category-deals/CategoryDeals";
import ApiService from "./services/ElasticsearchApi";

import { elasticSearchUrl } from "./common/util";
import ScrollButton from "./ScrollButton";

export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

export const getParameterByName = (name, url) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const App = () => {
  const [searchProduct, setSearchProduct] = useState(true);
  const [bestProducts, setBestProducts] = useState(0);
  const [couponKey, setCouponKey] = useState("");
  const [prevProductsStack, setPrevProductsStack] = useState([]);
  const [couponKeyStack, setCouponKeyStack] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState(0);
  const [categoryCouponKey,setCategoryCouponKey ] = useState("");
  const [prevCategoryProductsStack, setPrevCategoryProductsStack] = useState([]);
  const [categorycouponKeyStack, setCategoryCouponKeyStack] = useState([]);

  const [trendingDeals, setTrendingDeals] = useState(0);
  const [trendingDealsCouponKey, setTrendingDealsCouponKey] = useState("");
  const [prevTrendingDealsStack, setPrevTrendingDealsStack] = useState([]);
  const [
    trendingDealsCouponKeyStack,
    setTrendingDealsCouponKeyStack,
  ] = useState([]);
  const [banerCarousel, setBanerCarousel] = useState(0);

  var bestProductsReqBody = {
    size: 0,
    query: {
      bool: {
        must: [
          { match: { IS_HOT: "Y" } },
          {
            bool: {
              should: [{ term: { STATUS: 1 } }, { term: { STATUS: 2 } }],
            },
          },
        ],
      },
    },
    aggs: {
      distinct_coupons: {
        composite: {
          size: isDesktop ? 5 : isTablet ? 3 : 1,
          after: { coupon: couponKey },
          sources: [{ coupon: { terms: { field: "COUPON.keyword" } } }],
        },
        aggs: {
          top_dates: {
            top_hits: {
              size: 1,
              sort: [{ "DATE.keyword": { order: "asc" } }],
            },
          },
        },
      },
    },
  };
  function fetchProducts(bestProductsReqBody) {
    ApiService.getBestProducts(bestProductsReqBody)
      .then((data) => {
        const products = [];
        const buckets = data.aggregations.distinct_coupons.buckets;
        const newCouponKey =
          data.aggregations.distinct_coupons.after_key.coupon;

        setCouponKeyStack((prevKeyStack) => [...prevKeyStack, couponKey]);

        buckets.map((bucket) => {
          const hits = bucket.top_dates.hits.hits;
          const _doc_count = bucket.doc_count;
          let source = {};
          hits.map((hit) => {
            source = hit._source;
            source._id = hit._id;
            source._doc_count = _doc_count;
            products.push(source);
          });
        });
        setBestProducts(products);
        setCouponKey(newCouponKey);
        setPrevProductsStack((prevStack) => [...prevStack, bestProducts]);
      })
      .catch((error) => {
        console.error("Error in Best product fetch:", error);
      });
  }

  function handlePageChange() {
    fetchProducts(bestProductsReqBody);
  }
  function handlePrevPage() {
    const prevProducts = [...prevProductsStack];
    const prevCouponKeys = [...couponKeyStack];

    const lastProducts = prevProducts.pop();
    const lastCouponKey = prevCouponKeys.pop();

    setBestProducts(lastProducts);
    setCouponKey(lastCouponKey);
    setPrevProductsStack(prevProducts);
    setCouponKeyStack(prevCouponKeys);
  }
  // Category Deals fetch recoreds

  var CategoryDealsReqData = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            bool: {
              should: [{ term: { STATUS: 1 } }, { term: { STATUS: 2 } }],
            },
          },
        ],
      },
    },
    aggs: {
      distinct_coupons: {
        composite: {
          size: isDesktop ? 5 : isTablet ? 3 : 1,
          after: { coupon: categoryCouponKey },
          sources: [{ coupon: { terms: { field: "CATEGORY.keyword" } } }],
        },
        aggs: {
          top_dates: {
            top_hits: {
              size: 1,
              sort: [{ "CATEGORY.keyword": { order: "desc" } }],
            },
          },
        },
      },
    },
  };

  function fetchCategoryProducts(CategoryDealsReqData) {
    ApiService.getCategoryProducts(CategoryDealsReqData)
      .then((data) => {
        const categoryDealsProducts = [];
        const buckets = data.aggregations.distinct_coupons.buckets;
        const newCategoryCouponKey =
          data.aggregations.distinct_coupons.after_key.coupon;

          setCategoryCouponKeyStack((prevKeyStack) => [...prevKeyStack, categoryCouponKey]);

        buckets.map((bucket) => {
          const hits = bucket.top_dates.hits.hits;
          const _doc_count = bucket.doc_count;
          let source = {};
          hits.map((hit) => {
            source = hit._source;
            source._id = hit._id;
            source._doc_count = _doc_count;
            categoryDealsProducts.push(source);
          });
        });
        setCategoryProducts(categoryDealsProducts);
        setCategoryCouponKey(newCategoryCouponKey);
        setPrevCategoryProductsStack((prevStack) => [...prevStack, categoryProducts]);
      })
      .catch((error) => {
        console.error("Error in Category Products fetch:", error);
      });
  }

  function handleCategoryPage() {
    fetchCategoryProducts(CategoryDealsReqData);
  }
  function handlePrevCategoryPage() {
    const prevCategoryProducts = [...prevCategoryProductsStack];
    const prevCategoryCouponKeys = [...categorycouponKeyStack];

    const lastCategoryProducts = prevCategoryProducts.pop();
    const lastCategoryCouponKey = prevCategoryCouponKeys.pop();

    setCategoryProducts(lastCategoryProducts);
    setCategoryCouponKey(lastCategoryCouponKey);
    setPrevCategoryProductsStack(prevCategoryProducts);
    setCategoryCouponKeyStack(prevCategoryCouponKeys);
  }

  const pageSize = isDesktop ? 5 : isTablet ? 3 : 1;
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const [loading, setLoading] = useState(false);
   const [page, setPage] = useState(0);

  // Ternding Desals section
  

  function fetchTrendingDealsProducts(newPage) {
    var trendingDealsReqData = {
      "size": pageSize,
      "from": newPage * pageSize, // Correctly use "from" for pagination
      "query": {
        "bool": {
          "must": [
            { "match": { "IS_TRENDING": "Y" } },
            {
              "bool": {
                "should": [
                  { "term": { "STATUS": 1 } },
                  { "term": { "STATUS": 2 } }
                ]
              }
            }
          ]
        }
      },
      "sort": [
        { "DATE.keyword": { "order": "desc" } }, 
        { "RATING_STAR": { "order": "desc" } },
        { "DISCOUNT_PERCENTAGE": { "order": "desc" } },
        { "SELLER_NAME.keyword": { "order": "asc" } }
      ],
      "aggs": {
        "distinct_coupons": {
          "composite": {
            "size": isDesktop ? 5 : isTablet ? 3 : 1,
            "after": { "coupon": trendingDealsCouponKey },
            "sources": [
              { "coupon": { "terms": { "field": "COUPON.keyword" } } }
            ]
          },
          "aggs": {
            "top_dates": {
              "top_hits": {
                "size": 1,
                "sort": [
                  { "DATE.keyword": { "order": "desc" } }, 
                  { "RATING_STAR": { "order": "desc" } },
                  { "DISCOUNT_PERCENTAGE": { "order": "desc" } },
                  { "SELLER_NAME.keyword": { "order": "asc" } }
                ],
              }
            }
          }
        }
      }
    }
    ApiService.getTrendingDealsProducts(trendingDealsReqData)
    .then((data) => {
      const newTrendingDeals = data.hits.hits.map((hit) => ({
        ...hit._source,
        _id: hit._id
      }));
  
      setHasMore(data.hits.total.value > (newPage + 1) * pageSize);

      setPrevTrendingDealsStack((prevStack) => [...prevStack, trendingDeals]);
      setTrendingDeals(newTrendingDeals);
      setLoading(false); // Reset loading after data fetch
    })
    .catch((error) => {
      console.error("Error fetching Trending Deals:", error);
      setLoading(false);
    });
  }

  const handleTrendingDeals = () => {
    if (!loading && hasMore) {
      setLoading(true); 
      const newPage = page + 1;
      setPage(newPage);
      fetchTrendingDealsProducts(newPage);
    }
  };
  
  const handlePrevTrendingDeals = () => {
    if (!loading && prevTrendingDealsStack.length > 1) {
      const newPrevStack = [...prevTrendingDealsStack];
      const previousDeals = newPrevStack.pop();
      setPrevTrendingDealsStack(newPrevStack);
      setTrendingDeals(previousDeals);
      setPage((prev) => Math.max(prev - 1, 0));
      setHasMore(true); // Re-enable "Next" when going back
    }
  };
  

  var banerCarouselReqData = {
    size: 0,
    query: {
      bool: {
        must: [
          { match: { IS_BANNER: "Y" } },
          {
            bool: {
              should: [{ term: { STATUS: 1 } }, { term: { STATUS: 2 } }],
            },
          },
        ],
      },
    },
    aggs: {
      distinct_coupons: {
        composite: {
          size: isDesktop ? 5 : isTablet ? 3 : 1,
          after: { coupon: trendingDealsCouponKey },
          sources: [
            {
              coupon: {
                terms: {
                  field: "COUPON.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          top_dates: {
            top_hits: {
              size: 1,
              sort: [{ "DATE.keyword": { order: "desc" } }],
              _source: true,
            },
          },
        },
      },
    },
    sort: [
      { "DATE.keyword": { order: "desc" } } // Add sorting here
    ]
  };
  
  function fetchBanerCarousel(banerCarouselReqData) {
    ApiService.getBanerCarousel(banerCarouselReqData)
      .then((data) => {
        const banerCarouselData = [];
        const buckets = data.aggregations.distinct_coupons.buckets;

        buckets.map((bucket) => {
          const hits = bucket.top_dates.hits.hits;
          const _doc_count = bucket.doc_count;
          let source = {};
          hits.map((hit) => {
            source = hit._source;
            source._id = hit._id;
            source._doc_count = _doc_count;
            banerCarouselData.push(source);
          });
        });
        setBanerCarousel(banerCarouselData);
      })
      .catch((error) => {
        console.error("Error in TrendingDeals Products fetch:", error);
      });
  }

  useEffect(() => {
    fetchProducts(bestProductsReqBody);
    fetchCategoryProducts(CategoryDealsReqData);
    fetchTrendingDealsProducts(0);
    fetchBanerCarousel(banerCarouselReqData);
  }, []);

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="_doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
        <div className="container-fluid top-header" style={{ padding: 0 }}>
          <Header setSearchProduct={setSearchProduct} />
          {/* <BanerCarousel /> */}
          <ScrollButton />
          <ReactiveList
            componentId="Baner"
            dataField="DATE.keyword"
            defaultQuery={() => {
              return {
                query: {
                  bool: {
                    must: [
                      { match: { IS_BANNER: "Y" } },
                      {
                        bool: {
                          should: [
                            { match: { STATUS: 1 } },
                            { match: { STATUS: 2 } },
                          ],
                        },
                      },
                    ],
                  },
                },
              };
            }}
            sortBy="desc"
            render={({ data }) => {
              return <BanerCarousel data={data} />;
            }}
            size={20}
          />
          {/* <BanerCarousel
            data={
              banerCarousel && banerCarousel.length > 0 ? banerCarousel : []
            }
          /> */}
        </div>
        <div className="row pop-box">
          <div className="col-md-12  col-sm-12  marginBottom">
            <div>
              <div>
                <TrendingDeals
                  data={
                    trendingDeals && trendingDeals.length > 0
                      ? trendingDeals
                      : []
                  }
                />
                <div>
                <button
                  type="button"
                  className="btn btn-warning prev"
                  onClick={handlePrevTrendingDeals}
                  disabled={loading || prevTrendingDealsStack.length === 1} // Disable when loading or at first page
                >
                  <MdOutlineArrowBackIos />
                </button>

                <button
                type="button"
                className={`mb-2 btn btn-warning next ${loading || !hasMore ? "disabled" : ""}`} // Ensures visual disable effect
                onClick={handleTrendingDeals}
                disabled={loading || !hasMore} // Ensures button is functionally disabled
              >
                <MdOutlineArrowForwardIos />
              </button>
              </div>
              </div>
              <div>
                <BestProduct
                  data={
                    bestProducts && bestProducts.length > 0 ? bestProducts : []
                  }
                />
                <div>
                  <button
                    type="button"
                    className="btn btn-warning prev1"
                    onClick={handlePrevPage}
                    disabled={prevProductsStack.length === 1}
                  >
                    <MdOutlineArrowBackIos />
                  </button>

                  <button
                    type="button"
                    className="mb-2 btn btn-warning next1"
                    onClick={() => handlePageChange()}
                  >
                    <MdOutlineArrowForwardIos />
                  </button>
                </div>
              </div>
              <div>
                 <CategoryDeals
                  data={
                    categoryProducts && categoryProducts.length > 0 ? categoryProducts : []
                  }
                />
                <div>
                <button
                  type="button"
                  className="btn btn-warning  prev5"
                  onClick={handlePrevCategoryPage}
                  disabled={prevCategoryProductsStack.length === 1}
                >
                  <MdOutlineArrowBackIos />
                </button>

                <button
                  type="button"
                  className="mb-2 btn btn-warning next5"
                  onClick={handleCategoryPage}
                >
                  <MdOutlineArrowForwardIos />
                </button>
              </div>
                
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <br></br>
      </ReactiveBase>
    </div>
  );
};

export default Responsive(withRouter(App));
