import React from "react";

export const Footer = () => {
  return (
    <footer className="page-footer  center-on-small-only pt-0">
      <div className="container">
        <div className="row pt-5 mb-3 text-center d-flex justify-content-center">
          <div className="col-md-2 mb-3">
            <h6 className="title font-bold">
              <a href="/">Home</a>
            </h6>
          </div>

          <div className="col-md-2 mb-3">
            <h6 className="title font-bold">
              <a href="/buyers-guide/index.html">Buyer's Guide</a>
            </h6>
          </div>

          <div className="col-md-2 mb-3">
            <h6 className="title font-bold">
              <a href="/about-us">About Us</a>
            </h6>
          </div>

          <div className="col-md-2 mb-3">
            <h6 className="title font-bold">
              <a href="/terms-and-conditions">Terms & Conditions</a>
            </h6>
          </div>

          <div className="col-md-2 mb-3">
            <h6 className="title font-bold">
              <a href="/privacy-and-policy">Privacy Policy</a>
            </h6>
          </div>

          <div className="col-md-2 mb-3">
            <h6 className="title font-bold">
              <a href="/resources/public/contactus.html">Contact Us</a>
            </h6>
          </div>
        </div>
      </div>
      <div className="footer-copyright text-center">
        <div className="container-fluid">
          © Copyright 2023 PlanetOnSale. All Rights Reserved. All content,
          trademarks and logos are copyright of their respective owners.
        </div>
      </div>
      <br></br>
      <div className="footer-copyright text-center">
        <div className="container-fluid">
          <b>Disclosure:</b> Many links on the PlanetOnSale site are affiliate
          links. 
          {/* This site is a participant in the Amazon Associates Program, an
          affiliate advertising program designed to provide a means for sites to
          earn advertising fees by linking to amazon.in */}
        </div>
      </div>
    </footer>
  );
};
