// https://s.planetonsale.in
export const elasticSearchUrl = "https://admin:admin@s.planetonsale.in/";
// reverse proxy
// "http://13.235.58.216:8000/";

//export const elasticSearchUrl = "https://s.planetonsale.in/";
//" http://admin:admin@13.235.58.216:8000";
//
//export const elasticSearchUrl = "https://s.planetonsale.in";
export const restUrl = "http://localhost:8080";

export const OpenSearchUrl = "https://s.planetonsale.in/offer/_search?"

export const OpenSearchAuth = "Basic YWRtaW46YWRtaW4=" 

