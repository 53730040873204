import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../../layout/Header";
import { Footer } from "../../components/footer";
import ApiService from "../../services/Auth";
import { ReactiveBase } from "@appbaseio/reactivesearch";
import { elasticSearchUrl } from "../../common/util";
function ProfileName() {
  const history = useHistory();
  const location = useLocation();
  const data = location.state.reqData;
  const reqDataEmail = location.state.reqDataEmail;
  const [validated, setValidated] = useState(false);
  const [fullName, setFullName] = useState();

  const userRegister = {
    ...data,
    fullName: fullName,
  };

  const reqObj = {
    ...reqDataEmail,
    fullName: fullName,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (reqDataEmail) {
      history.push({ pathname: "/mobile", state: { reqObj } });
    } else {
      ApiService.registerOTP(userRegister)
        .then((data) => {
          if (data.success === true) {
            history.push({ pathname: "/" });
          }
        })
        .catch((error) => {
          console.error("Error in component:", error);
        });
    }
    setValidated(true);
  };

  return (
    <div>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="_doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
        <Header />
        <div className="container">
          <div className="row justify-content-center vertically">
            <div class="col-lg-4">
              <div className="mt-3">
                <Link to="/setpassword">
                  <BsArrowLeft className="text-dark" />
                </Link>
              </div>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h2 className="mt-4">
                  By what name <br /> should we call you?
                </h2>
                <p>Tell us your full name </p>
                <Row>
                  <Form.Group as={Col} md="12" controlId="validationFormik03">
                    <Form.Label>Enter full name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Kindly enter a your name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <div className="text-center">
                  <Link>Have a referral code?</Link>
                </div>

                <div className="mt-4 mb-5">
                  <Button
                    variant="warning"
                    className="col-sm-12 text-white"
                    type="submit"
                  >
                    Continue
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Footer />
      </ReactiveBase>
    </div>
  );
}
export default ProfileName;
