import React from "react";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const BanerCarousel = ({ data }) => {

  const settings = {
    slidesToShow: 3, 
    slidesToScroll: 3, 
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    dots: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  
  return (
    <div className="carousel-inner ">
      <Slider {...settings}>
        {data.map((item, i) => (
          <div key={i} className="my-2">
            <div className="mx-3"> 
              <a href={"/url?id=" + item.ID} target="_blank" rel="nofollow">
                <img
                  src={item.IMAGE_URL}
                  alt={item.title}
                  className="carousel-image"
                  height={240}
                />
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BanerCarousel;
