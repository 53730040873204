import React from "react";
import { useHistory } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import { ResultCard } from "@appbaseio/reactivesearch";
import "../App.css";
export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

  

const TrendingDeals = ({ data }) => {
  const history = useHistory();
  const redirectUrl = (value) => {
    //return window.open(`/url?${value}`, "_blank");
  };

  const openUrl = (value) => {
    ``; //return window.open(`/search?q=%22${value}%22`, "_self");
  };

  const handleViewAllClick = () => {
    history.push({ pathname: "/trending-deals" });
  };

  return (
    <div className="container-fluid">
      <div className=" d-flex justify-content-between">
        <div>
          <h5 className="my-3">TRENDING DEALS</h5>
        </div>
        <div>
          <h5 className="my-3" onClick={handleViewAllClick}>
            <Link to="/trending-deals" className="Link text-dark">
              VIEW ALL <MdArrowForwardIos />
            </Link>
          </h5>
        </div>
      </div>

      <div className="HomePageView container-fluid row ">
        {data.map((item, index) => (
          <div className="col-xs-12  col-sm-12 col-md-4 col-lg-3" key={index}>
            <ResultCard
              key={item.id}
              href={
                item._doc_count > 1
                  ? "/search?q=%22" + item.TITLE + "%22"
                   : "/url?id=" + item.ID
              }
              target={item._doc_count > 1 ? "_self" : "_blank"}
            >
              <ResultCard.Description>
                <span>
                  <div className="ps-capitalize">
                    {item._doc_count > 1 && isNaN(item.COUPON)
                      ? item.COUPON
                      : null}
                  </div>
                </span>
                <span>
                  <div
                    className="ps-compare-price"
                    onClick={(e) => {
                      window.location.href =
                        item._doc_count > 1
                          ? "/search?q=%22" +
                            item.TITLE +
                            "%22" +
                            `&title=${item.MODEL}`
                          : "/url?id=" + item.ID;
                    }}
                  >
                    {item._doc_count > 1 ? "Compare Price (" : null}{" "}
                    <span className="item-count">
                      {item._doc_count > 1 ? item._doc_count : null}
                    </span>{" "}
                    <span className="for-store">
                      {item._doc_count > 1 ? "Stores)" : null}
                    </span>
                    <div> </div>......
                  </div>
                </span>
              </ResultCard.Description>

              <ResultCard.Image src={item.IMAGE_URL} />
              <ResultCard.Description>
                {item.BRAND ? (
                  <div className="text-center">
                    Brand: <b>{item.BRAND}</b>
                  </div>
                ) : (
                  <div>
                    <span className="align-left">
                      <span className="badge  badge-success">
                        {item.RATING_STAR
                          ? rating(item.RATING_STAR) + "*"
                          : null}{" "}
                      </span>
                      <span className="small card-subtitle mb-2 text-muted">
                        {" "}
                        {item.RATING_COUNT
                          ? "(" + item.RATING_COUNT + ")"
                          : null}
                      </span>
                    </span>

                    <span className="float-right">
                      @{" "}
                      <span className="authors-list mb-2 text-muted">
                        {item.SELLER_NAME}
                      </span>
                    </span>
                  </div>
                )}
              </ResultCard.Description>

              <ResultCard.Title>
                <div
                  className="text-wrap"
                  dangerouslySetInnerHTML={{
                    __html: item.TITLE,
                  }}
                />
                <span>
                  <br />
                </span>
              </ResultCard.Title>

              <ResultCard.Description>
                <div>
                  <span>
                    <small>
                      <strike>
                        {item.ORIGINAL_PRICE
                          ? numberFormat(item.ORIGINAL_PRICE)
                          : null}
                      </strike>
                    </small>
                    <b className="ml-2">
                      {item.OFFER_PRICE ? numberFormat(item.OFFER_PRICE) : null}{" "}
                    </b>
                  </span>
                  <span className="float-right">
                    <div className="badge  badge-warning">
                      <span>(</span>
                      <span>
                        {item.DISCOUNT_PERCENTAGE
                          ? item.DISCOUNT_PERCENTAGE + "% OFF"
                          : "Get-Price"}
                      </span>
                      <span>)</span>
                    </div>
                  </span>
                </div>
              </ResultCard.Description>
            </ResultCard>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingDeals;
