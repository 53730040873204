import React, { useState, useEffect } from "react";
import Footer from "../layout/Footer";
import Breadcrumbs from "../layout/Breadcrumb";
import Menu from "../Mobile/Menu";
import { Link } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import "../App.css";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Header from "../layout/Header";
import { elasticSearchUrl } from "../common/util";
import {
  ReactiveBase,
  MultiList,
  ReactiveList,
  SelectedFilters,
  ResultCard,
} from "@appbaseio/reactivesearch";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollButton from "../ScrollButton";

export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

const ViewAllCategoryDeals = () => {
  const [selectedModal, setSelectedModal] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isMobile, setIsMobile] = useState(true);
  const [checkIsMobile, setCheckIsMobile] = useState(false);
  const [filter, setFilter] = useState(true);
  const [sideMenuClicked, setSideMenuClicked] = useState([]);
  const [sideMenu, setSideMenu] = useState(false);
  const [sidePriceMenu, setSidePriceMenu] = useState(false);
  const [searchProduct, setSearchProduct] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobile(false);
        setCheckIsMobile(true);
      } else {
        setIsMobile(true);
        setCheckIsMobile(true);
      }
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filterData = (visible) => {
    setSideMenu(visible);
    setFilter(false);
  };
  const filterPriceData = (visible) => {
    setSidePriceMenu(visible);
  };

  const applyData = () => {
    setSideMenu(false);
    setFilter(true);
  };

  const checkboxScrollForSingleArray = (value) => {
    const length = value.length;

    if (length > 0) {
      const arr = [length];
      const mainArray = [...sideMenuClicked, arr];
      setSideMenuClicked(mainArray);
    }

    if (length === 0) {
      const updatedSideMenuClicked = [...sideMenuClicked];
      updatedSideMenuClicked.pop();
      setSideMenuClicked(updatedSideMenuClicked);
    }
  };

  const redirectUrl = (value) => {
    //return window.open(`/url?${value}`, "_blank");
  };
  const openUrl = (value) => {
    ``; //return window.open(`/search?q=%22${value}%22`, "_self");
  };

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="_doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >        
        <Header setSearchProduct={setSearchProduct} />
        <div>
          <Breadcrumbs />
        </div>
        <div className="row">
          <div
            className="col-12 col-lg-3 col-md-3 col-sm-4 pad-rm filter"
            style={{
              display: sideMenu ? "" : "none",
              position: "fixed",
              zIndex: "99999",
              bottom: "40px",
              height: "95%",
            }}
          >
            {/* <div className="col-12 col-lg-3 col-md-3 col-sm-8 tab-setup-left">
              <div className="tab-wrapper">
                <h1>Best Products OnSale & Live Deals in India.</h1>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <Tab.Container defaultActiveKey="model">
                        <Row>
                          <Col sm={4} className="de-nn">
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="model">
                                  Best Products
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link eventKey="category">
                                  Category
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={8} className="right-box">
                            <Tab.Content>
                              <Tab.Pane eventKey="model">
                                <div className="box">
                                  <MultiList
                                    onValueChange={(e) => setSelectedModal(e)}
                                    componentId="ModelFilter"
                                    dataField="MODEL.keyword"
                                    showSearch={true}
                                    showCount={true}
                                    title="Best Products"
                                    placeholder="Search Best Product"
                                    sortBy="asc"
                                    react={{
                                      and: "q",
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="category">
                                <div className="box">
                                  <MultiList
                                    onValueChange={(e) =>
                                      setSelectedCategory(e)
                                    }
                                    componentId="CategorySensor"
                                    dataField="CATEGORY.keyword"
                                    showCount={true}
                                    showSearch={false}
                                    size={20}
                                    title="Category"
                                    placeholder="Search from Category"
                                    filterData={true}
                                    react={{
                                      and: "q",
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="d-none d-md-block  col-md-2 col-12 col-lg-6  col-sm-12 ">
            {/* <div className="pl-2">
              <p className="border-bottom pb-1">FILTER BY</p>
              <div className="border-bottom pb-3">
                <div className="box">
                  <MultiList
                    onValueChange={(e) => setSelectedModal(e)}
                    componentId="ModelFilter"
                    dataField="MODEL.keyword"
                    showSearch={true}
                    showCount={true}
                    title="Best Products"
                    placeholder="Search Best Product"
                    sortBy="asc"
                    react={{
                      and: "q",
                    }}
                  />
                </div>

                <div className="box">
                  <MultiList
                    onValueChange={(e) => setSelectedCategory(e)}
                    componentId="CategorySensor"
                    dataField="CATEGORY.keyword"
                    showCount={true}
                    showSearch={false}
                    size={20}
                    title="Category"
                    placeholder="Search from Category"
                    filterData={true}
                    react={{
                      and: "q",
                    }}
                  />
                  <button
                    onClick={() => {
                      applyData();
                    }}
                    className="bestaply"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          {/* <button onClick={() => filterData(!sideMenu)} className="bestbtnss">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="result-icon"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" opacity="0.05"></path>
                <path
                  fill="#282C3F"
                  d="M3.749 7.508a.75.75 0 010-1.5h3.138a2.247 2.247 0 014.243 0h9.121a.75.75 0 010 1.5h-9.126a2.248 2.248 0 01-4.232 0H3.749zm13.373 9h3.129a.75.75 0 010 1.5h-3.135a2.247 2.247 0 01-4.231 0H3.749a.75.75 0 010-1.5h9.13a2.248 2.248 0 014.243 0z"
                ></path>
              </g>
            </svg>
            Filter
          </button> */}
          {/* <button
            onClick={() => filterPriceData(!sidePriceMenu)}
            className="bestbtnss sortBtn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M8 16H4l6 6V2H8zm6-11v17h2V8h4l-6-6z"
              />
            </svg>
            Sort
          </button> */}
          <div className=" col-sm-12 col-md-12 col-lg-12 justify-content-center">
            <h4 className="text-center"> DEALS BY CATEGORY </h4>
            <SelectedFilters />
            <ScrollButton/>
           <ReactiveList
              componentId="CategoryDeals"
              showResultStats={true}
              dataField="CATEGORY"
              aggregationField="CATEGORY.keyword"
              // defaultQuery={() => {
              //         return {
              //           "size": 0,
              //           "aggs": {
              //             "distinct_categories": {
              //               "terms": {
              //                 "field": "CATEGORY.keyword",
              //                 "size": 10
              //               },
              //               "aggs": {
              //                 "category_image_urls": {
              //                   "terms": {
              //                     "field": "CATEGORY_IMAGE_URL.keyword",
              //                     "size": 1  // Assuming each category has one image URL, adjust as needed
              //                   }
              //                 }
              //               }
              //             }
              //           }
              //         }
              // }}
              react={{
                and: ["TrendingSensor", "q"],
              }}
              size={10000}
              pagination={false}
              scrollOnChange={false}
              render={({ aggregationData, data }) => {
                const resultSet = [];
                data.map((dataItem) => {
                  aggregationData.map((agItem) => {
                    if (dataItem.ID === agItem.ID) {
                      resultSet.push({
                        ...dataItem,
                        ...agItem,
                      });
                    }
                  });
                });
                return (
                  <ReactiveList.ResultCardsWrapper>
                    <div className="row d-flex justify-content-center">
                      {resultSet.map((item,index) => (
                        <div className="d-flex  ml-2 col-lg-5 col-md-5 col-xl-3 col-sm-12 justify-content-center" key={index}>
                          <ResultCard
                          // href={"/category?q=" + encodeURIComponent(item.CATEGORY)}
                            key={item.id}
                            onClick={() => { window.location.href = "/category?q=" + encodeURIComponent(item.CATEGORY); }}
                            >
                            <ResultCard.Description></ResultCard.Description>
                            <ResultCard.Image src={item.CATEGORY_IMAGE_URL} />
                            <ResultCard.Description></ResultCard.Description>

                            <ResultCard.Title>
                              <div className="text-center">
                                {" "}
                                <h6>{item.CATEGORY}</h6>
                              </div>
                              <span>
                                <br />
                              </span>
                            </ResultCard.Title>

                            <ResultCard.Description></ResultCard.Description>
                          </ResultCard>
                        </div>
                      ))}
                    </div>
                  </ReactiveList.ResultCardsWrapper>
                );
              }}
            /> 
          </div>
        </div>

        <Footer />
        <br></br>
      </ReactiveBase>
    </div>
  );
};

export default ViewAllCategoryDeals;
