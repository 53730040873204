import { OpenSearchUrl, OpenSearchAuth } from "../common/util";

const getBestProducts = (data) => {
    return fetch(`${OpenSearchUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": OpenSearchAuth, 
        },
        body: JSON.stringify(data),
      })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw console.error(error);
      });
  };

  const getCategoryProducts = (data) => {
    return fetch(`${OpenSearchUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": OpenSearchAuth, 
        },
        body: JSON.stringify(data),
      })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw console.error(error);
      });
  };

  const getTrendingDealsProducts = (data) => {
    return fetch(`${OpenSearchUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": OpenSearchAuth, 
        },
        body: JSON.stringify(data),
      })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw console.error(error);
      });
  };
  const getBanerCarousel = (data) => {
    return fetch(`${OpenSearchUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": OpenSearchAuth, 
        },
        body: JSON.stringify(data),
      })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw console.error(error);
      });
  };

  export default {getBestProducts,getCategoryProducts, getTrendingDealsProducts, getBanerCarousel};
  