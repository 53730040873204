import React, { useState, useEffect } from "react";
import {
  ReactiveBase,
  SearchBox,
  ReactiveList,
} from "@appbaseio/reactivesearch";
import ".././App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Responsive from "react-responsive-decorator";
import Menu from "../Mobile/Menu";
// import BootstrapCarouselDynamicDummy from ".././BootstrapCarouselDynamicDummy";
import { withRouter, useLocation } from "react-router-dom";
import ScrollButton from "./ScrollButtonSearch";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ApiService from "../services/Auth";
import { IoIosArrowDown } from "react-icons/io";
import Breadcrumbs from "../layout/Breadcrumb";
import { elasticSearchUrl } from "./util";
import { getParameterByName } from "../App";
import { buyersGuideLinks } from "./common";
import Modal from "react-bootstrap/Modal";

export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

export const scrollModify = { bottom: "50px" };

const App = () => {

  const redirectUrl = (value) => {
    console.log(value);
    return window.open(`/url?${value}`, "_blank");
  };

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get("q");
  const history = useHistory();
  const [isLogedin, setIsLogedin] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [searchProduct, setSearchProduct] = useState(true);
  const [record, setRecord] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [firstState, setFirstState] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setIsLogedin(true);
      ApiService.getUserDetails()
        .then((data) => {
          console.log(data.data);
          setUserDetail(data.data);
        })
        .catch((error) => {
          console.error("Error in component:", error);
        });
    }
  }, [token]);

  const handleLogin = () => {
    if (token) {
      setIsLogedin(true);
    } else {
      history.push("/login");
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    history.push("/");
    setIsLogedin(false);
  };

  // const handleChangeUrl = () =>{
  //   navigate(`/url?${item.OFFER_URL}`)
  // }

  const getBuyersGuideRedirectLink = (data) => {
    if (data.length) {
      const title = data[0].MODEL;
      return buyersGuideLinks[title]
        ? `https://planetonsale.in/buyers-guide/${buyersGuideLinks[title]}`
        : `https://planetonsale.in/buyers-guide/index.html`;
    }
  };
  const getProductTitle = (data) => {
    if (data.length && data[0].MODEL) {
      return data[0].MODEL;
    }
    return "";
  };
  const handleShowModel = (data) => {
    setModalShow(true);
    setRecord(data);
  };

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
        <div className="container-fluid top-header" style={{ padding: 0 }}>
          <nav className="navbar navbar-expand-lg navbar-light bg-header">
            <div className="d-flex justify-content-between">
              <a className="navbar-brand logo-img" href="/">
                <img
                  src="https://d2qjxo7zbjilfg.cloudfront.net/images/7a602a38-a76b-4864-a40d-2d7bd6428800.png"
                  height="60"
                  alt="Logo of PlanetOnSale"
                />
                <b> PlanetOnSale </b>
              </a>
              <div className="mt-2">
                <Menu />
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              ></div>
            </div>
            <div className="col-lg-8 dataSearch">
              <SearchBox
                className="datasearch"
                componentId="q"
                // onValueSelected={(e) => {
                //   window.location.href = `${window.location.protocol}//${
                //     window.location.host
                //   }/?q=%22${encodeURI(e)}%22`;
                // }}
                // onValueChange={(e) => {
                //   window.location.href = `${window.location.protocol}//${window.location.host}/search?q=%22${encodeURI(e)}%22`;
                //   document.activeElement.blur();
                // }}
                onValueSelected={(e) => {
                  window.location.href = `${window.location.protocol}//${
                    window.location.host
                  }/search?q=%22${encodeURI(e)}%22`;
                  document.activeElement.blur();
                }}
                onValueChange={(e) => {
                  if (e && e.trim().length === 1) {
                    setSearchProduct(false);
                  }
                }}
                // onValueChange={(e) => {
                //   if (e && e.trim() !== "") { // Check if the search query is not empty

                //   }
                // }}
                dataField={[
                  "SEARCH_STR1",
                  "SEARCH_STR1.SEARCH",
                  "SEARCH_STR2",
                  "SEARCH_STR2.SEARCH",
                  "TITLE",
                  "TITLE.SEARCH",
                  "CATEGORY",
                  "BRAND",
                  "SELLER_NAME",
                ]}
                queryFormat="and"
                placeholder="Search for a deal (product) "
                innerClass={{
                  input: "searchbox",
                  list: "suggestionlist",
                }}
                strictSelection={true}
                autosuggest={true}
                showVoiceSearch={true}
                iconPosition="left"
              />
            </div>

            <li className="nav-item new-navset">
              <a href="/buyers-guide/index.html">Buyer's Guide</a>
            </li>
            {/* {isLogedin ? (
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              overlay={
                <Popover className="mt-3 overLay">
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/my-earnings"> My Earnings </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/my-orders"> My Orders </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/withdraw-money"> Withdraw Money </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/withdrawal-history"> Withdrawl History </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark"> Support Ticket </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/personaldetails">
                      Setting
                    </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" onClick={logoutHandler}>
                      Logout
                    </Link>
                  </Popover.Title>
                </Popover>
              }
            >
              <li className="d-flex justify-content-between  nav-item new-navset ">
              <span class="border-left border-dark py-2 ml-2"></span>
                <div className="earningsTitle">
                <Link to="/my-earnings" >My Earnings ₹901.00</Link>
                </div>
                <span class="border-right border-dark py-2 ml-2"></span>
                <div className="btn ml-1" onClick={handleLogin}>
                  <IoPersonCircleOutline size={30} />
                  <small className="mt-1 mx-1 text-white">{userDetail.fullName ? userDetail.fullName : (userDetail.tempEmail && userDetail.tempEmail.split('@')[0])}</small>
                  <IoIosArrowDown />
                </div>
              </li>
            </OverlayTrigger>
          ) : (
            <li className="loginUser nav-item new-navset">
              <div className="btn" onClick={handleLogin}>
                <IoPersonCircleOutline size={30} />
              </div>
            </li>
          )} */}
          </nav>
        </div>
        <Breadcrumbs />
        <div className="row pop-box mt-4 justify-content-center">
          <div className="col-12 col-lg-9 col-md-9 col-sm-12  marginBottom">
            <ScrollButton />
            <ReactiveList
              componentId="SearchResult"
              dataField="FINAL_PRICE"
              defaultQuery={() => {
                if (searchProduct) {
                  return {
                    query: {
                      bool: {
                        must: [
                          { match: { TITLE: q } },
                          {
                            bool: {
                              should: [
                                { match: { STATUS: 1 } },
                                { match: { STATUS: 2 } },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    // sort: [{ FINAL_PRICE: { order: "desc" }}] 
                  };
                } else {
                  return {
                    query: {},
                  };
                }
              }}
              size={1000}
              react={{
                and: [
                  "q",
                  "ratingsFilter",
                  "BestSellingSensor",
                  "StoreSensor",
                  "authorFilter",
                  "CategorySensor",
                  "BrandFilter",
                  "StoreFilter",
                  "DateRangeSensor",
                  "PriceSensor",
                  "PriceInputSensor",
                  "TrendingSensor",
                  "ModelFilter",
                ],
              }}
              render={({ data }) => {
                data = data.sort((a, b) => (a.FINAL_PRICE ? a.FINAL_PRICE : 0) - (b.FINAL_PRICE ?  b.FINAL_PRICE : 0));
                const firstImageUrl = data.length > 0 ? data[0].IMAGE_URL : "";
                return (
                  <div className="row">
                    <div className="col-sm-4">
                      {firstImageUrl && (
                        <img
                          className="detailPageImg"
                          src={firstImageUrl}
                          alt="First Image"
                        />
                      )}
                    </div>
                    <div className="col-sm-8">
                      <h5>{data.length > 0 ? data[0].TITLE : ""}</h5>
                      <small>Choose the best price and retailer</small>

                      <ReactiveList.ResultCardsWrapper>
                        {/* show products in table */}
                        <div className="d-none d-md-block">
                          <Table bordered hover size="md">
                            <thead className="bg-light">
                              <tr className="tableHeading">
                                <th>Store</th>
                                <th>Store Price </th>
                                <th>PS Cashback (BETA)</th>
                                <th>Final Price</th>
                                <th>GRAB DEAL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data &&
                                data.map(
                                  (item, index) =>
                                    (item.STATUS === 1 ||
                                      item.STATUS === 2) && (
                                      <tr key={index}>
                                        <td class="text-center">
                                          {item.SELLER_LOGO_URL ? (
                                            <img
                                              src={item.SELLER_LOGO_URL}
                                              class="rounded"
                                              alt="seller-img"
                                              width={100}
                                            />
                                          ) : (
                                            item.SELLER_NAME
                                          )}{" "}
                                        </td>
                                        <td>
                                          <span>
                                            <small>
                                              <strike>
                                                {item.ORIGINAL_PRICE
                                                  ? numberFormat(
                                                      item.ORIGINAL_PRICE
                                                    )
                                                  : null}
                                              </strike>
                                            </small>
                                            <b className="ml-1">
                                              {item.OFFER_PRICE
                                                ? numberFormat(item.OFFER_PRICE)
                                                : null}
                                            </b>
                                          </span>
                                        </td>
                                        <td>
                                          {item.CASHBACK_AMOUNT ? (
                                            numberFormat(item.CASHBACK_AMOUNT)
                                          ) : (
                                            <p>NA</p>
                                          )}
                                        </td>
                                        <td>
                                          {item.FINAL_PRICE 
                                            ? numberFormat(item.FINAL_PRICE)
                                            : numberFormat(item.OFFER_PRICE)  }
                                        </td>
                                        <td>
                                          <a
                                            href={"/url?id=" + item.ID}
                                            target="_blank"
                                            // onClick={() => {
                                            //   window.open(
                                            //     // `/url?${item.OFFER_URL}`,
                                            //     "/url?id=" + item.ID,
                                            //     // `/out?id=${item.ID}`,
                                            //     "_blank"
                                            //   );
                                            // }}
                                          >
                                            <button
                                              class="btn btn-secondary btn-sm btn-warning "
                                              disabled={
                                                item.STATUS !== 1 ? true : false
                                              }
                                            >
                                              <small>
                                                {item.STATUS === 1
                                                  ? "GRAB DEAL"
                                                  : item.STATUS === 2
                                                  ? "OUT OF STOCK"
                                                  : ""}
                                              </small>
                                            </button>
                                          </a>
                                        </td>
                                      </tr>
                                    )
                                )}
                            </tbody>
                          </Table>
                          <div className="ml-5 buyers-guide-link">
                            Still undecided! Please check our{" "}
                            <a href={getBuyersGuideRedirectLink(data)}>
                              {getProductTitle(data)}
                            </a>{" "}
                            Buyer's guide
                          </div>
                        </div>
                        <div className="d-md-none d-sm-block col-sm-12">
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Store</th>
                                <th>Final Price</th>
                                <th>GRAB DEAL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data &&
                                data.map(
                                  (item, index) =>
                                    (item.STATUS === 1 ||
                                      item.STATUS === 2) && (
                                      <tr
                                        key={index}
                                       
                                      >
                                        <td class="text-center"  onClick={() => handleShowModel(item)}>
                                          {item.SELLER_LOGO_URL ? (
                                            <img
                                              src={item.SELLER_LOGO_URL}
                                              class="rounded"
                                              alt="seller-img"
                                              width={70}
                                            />
                                          ) : (
                                            item.SELLER_NAME
                                          )}{" "}
                                        </td>
                                        <td  onClick={() => handleShowModel(item)}>
                                          {item.FINAL_PRICE
                                            ? numberFormat(item.FINAL_PRICE)
                                            : numberFormat(item.OFFER_PRICE)}
                                        </td>
                                        <td>
                                          <a
                                            href={ "/url?id=" + item.ID}
                                            target="_blank"
                                            // onClick={() => {
                                            //   window.open(
                                            //     // `/url?${item.OFFER_URL}`,
                                            //     `/out?id=${item.ID}`,
                                            //     "_blank"
                                            //   );
                                            // }}
                                          >
                                            <button
                                              disabled={
                                                item.STATUS !== 1 ? true : false
                                              }
                                             
                                              // onClick={() => {
                                              //   if (item.STATUS === 1) {
                                              //     window.open(
                                              //       `/out?id=${item.ID}`,
                                              //       "_blank"
                                              //     );
                                              //   }
                                              // }}
                                              className="btn btn-secondary btn-sm btn-warning"
                                            >
                                              <small>
                                                {item.STATUS === 1
                                                  ? "GRAB DEAL"
                                                  : item.STATUS === 2
                                                  ? "OUT OF STOCK"
                                                  : ""}
                                              </small>
                                            </button>
                                          </a>
                                        </td>
                                      </tr>
                                    )
                                )}
                            </tbody>
                          </Table>
                          <div className="ml-5 buyers-guide-link">
                            Still undecided! Please check our{" "}
                            <a href={getBuyersGuideRedirectLink(data)}>
                              {getProductTitle(data)}
                            </a>
                            Buyer's guide
                          </div>
                        </div>
                        {/* <div className="d-md-none d-sm-block col-sm-12">
                       <div class="d-flex">
                          <div class="mr-auto p-2">
                            <p>Store</p>
                          </div>
                          <div class="p-2">
                            <p>Final Price</p>
                        </div>
                      </div>
                      {data.map((item)=>(
                        <div class="d-flex" key={item._ID}>
                        <div class="mr-auto p-2">
                          {item.SELLER_LOGO_URL ? (
                                      <img
                                        src={item.SELLER_LOGO_URL}
                                        class="rounded"
                                        alt="seller-img"
                                        width={70}
                                      />
                                    ) : (
                                      item.SELLER_NAME
                                    )}
                        </div>
                        <div class="p-2">
                        {item.FINAL_PRICE
                                      ? numberFormat(item.FINAL_PRICE)
                                      : numberFormat(item.OFFER_PRICE)} <br/>
                                      <button className="mt-2"
                                      disabled={
                                        item.STATUS !== 1 ? true : false
                                      }
                                      class="btn btn-secondary btn-sm btn-warning"
                                    >
                                      <small className="mt-1">
                                        {item.STATUS === 0
                                          ? "PENDING"
                                          : item.STATUS === 1
                                          ? "GRAB DEAL"
                                          : item.STATUS === 2
                                          ? "OUT OF STOCK"
                                          : ""}
                                      </small>
                                    </button>
                      </div>
                    </div>
                      ))}
                       </div> */}
                      </ReactiveList.ResultCardsWrapper>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <footer className="page-footer  center-on-small-only pt-0">
          <div className="container">
            <div className="row pt-5 mb-3 text-center d-flex justify-content-center">
              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/">Home</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/buyers-guide/index.html">Buyer's Guide</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/about-us">About Us</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/privacy-and-policy">Privacy Policy</a>
                </h6>
              </div>

              <div className="col-md-2 mb-3">
                <h6 className="title font-bold">
                  <a href="/resources/public/contactus.html">Contact Us</a>
                </h6>
              </div>
            </div>
          </div>
          <div className="footer-copyright text-center">
            <div className="container-fluid">
              © Copyright 2025 PlanetOnSale. All Rights Reserved. All content,
              trademarks and logos are copyright of their respective owners.
            </div>
          </div>
          <br></br>
          <div className="footer-copyright text-center">
            <div className="container-fluid">
              <b>Disclosure:</b> Many links on the PlanetOnSale site are
              affiliate links.
               {/* This site is a participant in the Amazon
              Associates Program, an affiliate advertising program designed to
              provide a means for sites to earn advertising fees by linking to
              amazon.in */}
            </div>
          </div>
        </footer>
        {/* ... rest of your JSX */}
      </ReactiveBase>

      <Modal
        style={{ marginTop: 120 }}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div class="d-flex ">
            <div class="mr-auto ">
              <p>Store</p>
            </div>
            <div>
              <p>{record.SELLER_NAME}</p>
            </div>
          </div>
          <div class="d-flex ">
            <div class="mr-auto ">Store Price</div>
            <div>
              <span>
                <small>
                  <strike>
                    {record.ORIGINAL_PRICE
                      ? numberFormat(record.ORIGINAL_PRICE)
                      : null}
                  </strike>
                </small>
                <b>
                  {record.OFFER_PRICE ? numberFormat(record.OFFER_PRICE) : null}
                </b>
              </span>
            </div>
          </div>
          <div class="d-flex mt-3">
            <div class="mr-auto">PS Cashback (BETA)</div>
            <div>
              {record.CASHBACK_AMOUNT ? (
                numberFormat(record.CASHBACK_AMOUNT)
              ) : (
                <p>NA</p>
              )}
            </div>
          </div>
          <div class="d-flex my-2">
            <div class="mr-auto">Final Price</div>
            <div>
              {" "}
              {record.FINAL_PRICE
                ? numberFormat(record.FINAL_PRICE)
                : numberFormat(record.OFFER_PRICE)}
            </div>
          </div>
          <div class="d-flex">
            <div class="mr-auto ">Grab Deal</div>
            <div>
              <a
                href={`/out?id=${record.ID}`}
                target="_blank"
                // onClick={() => {
                //   window.open(
                //     // `/url?${item.OFFER_URL}`,
                //     `/out?id=${item.ID}`,
                //     "_blank"
                //   );
                // }}
              >
                <button
                  disabled={record.STATUS !== 1 ? true : false}
                  // onClick={() => {
                  //   window.open(`/url?${record.OFFER_URL}`, "_blank");
                  // }}
                  class="btn btn-secondary btn-sm btn-warning"
                >
                  <small>
                    {record.STATUS === 1
                      ? "GRAB DEAL"
                      : record.STATUS === 2
                      ? "OUT OF STOCK"
                      : ""}
                  </small>
                </button>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Responsive(withRouter(App));
