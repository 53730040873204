import React, { useState, useEffect } from "react";
import "../App.css";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { X } from "react-feather";
import { IoPersonCircleOutline } from "react-icons/io5";
import { Link, useHistory } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  closeMenu: {
    float: "right",
    marginRight: "10px",
  },
});

function Menu() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    bottom: false,
  });
  const history = useHistory();
  const [isLogedin, setIsLogedin] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setIsLogedin(true);
    }
  }, [token]);
  const handleLogin = () => {
    if (token) {
      setIsLogedin(true);
    } else {
      history.push("/login");
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    setIsLogedin(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <span className={classes.closeMenu}>
          <X size={25} />
        </span>
        {/* <div onClick={handleLogin} className="col-md-2 mb-2 pb-2 border-bottom">
            <IoPersonCircleOutline size={30} /> login / signup
        </div> */}

        <div className="col-md-2 mb-2 ">
          <h6 className="title font-bold">
            <a href="/">Home</a>
          </h6>
        </div>

        <div className="col-md-2 mb-2">
          <h6 className="title font-bold">
            <a href="/buyers-guide/index.html">Buyer's Guide</a>
          </h6>
        </div>

        <div className="col-md-2 mb-2">
          <h6 className="title font-bold">
            <a href="/about-us">About Us</a>
          </h6>
        </div>

        <div className="col-md-2 mb-2">
          <h6 className="title font-bold">
            <a href="/terms-and-conditions">Terms & Conditions</a>
          </h6>
        </div>

        <div className="col-md-2 mb-2">
          <h6 className="title font-bold">
            <a href="/privacy-and-policy">Privacy Policy</a>
          </h6>
        </div>

        <div className="col-md-2 mb-2 pb-2 border-bottom">
          <h6 className="title font-bold">
            <a href="/resources/public/contactus.html">Contact Us</a>
          </h6>
        </div>
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <span
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </span>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Menu;
